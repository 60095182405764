import styles from '../styles/profile.module.scss'
import Button from "../../../components/shared/Button/Button";
import {useDispatch} from "react-redux";
import {setOpenModal} from "../../../store/slices/modals.slice";
import {useTranslation} from "react-i18next";
import useSubscriptions from "../../../hooks/useSubscriptions";

const RemoveSubscriptionModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {cancelSubscribe} = useSubscriptions();

  const removeHandler = async () => {
    await cancelSubscribe();
    dispatch(setOpenModal('removeSubscriptionSuccess'))
  }

  return (
    <div className={styles.subscriptionModalWrapper}>
      <div className={styles.removeSubModalTitle}>{t('removeSubModalTitle')}</div>
      <div className={styles.removeSubModalDescr}>{t('removeSubModalDescr')}</div>
      <div className={styles.subscriptionModalButtonWrapper}>
        <Button title={t('saveSub')} submit={() => {}} className={styles.confirm} />
        <Button title={t('removeSub')} submit={removeHandler} className={styles.cancel} />
      </div>
    </div>
  )
}

export default RemoveSubscriptionModal;
