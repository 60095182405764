import {DEFAULT_WHITE, MAIN_WHITE} from "../constants/colors";
import {AR, EN, UA} from "../constants/local";

export const stokeHandler = (hovered: boolean, selected: boolean): string => {
  if (selected) {
    return DEFAULT_WHITE;
  }
  if (hovered) {
    return MAIN_WHITE;
  }

  return ''
};

export const timeFormat = (duration: any) => {
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  let ret = "";
  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
};

export const debounce = (func: any, delay: number) => {
  let timeoutId: any;
  return function(...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}
export function truncateString(input: string, max: number): string {
  if (input.length > max) {
    return input.substring(0, max) + '...';
  } else {
    return input;
  }
}

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(() => {
  }).catch((err) => {
    console.error('Ошибка при копировании текста: ', err);
  });
};

export const languageHandler = (lng: string) => {
  if (lng === "en") {
    return EN
  }
  if (lng === "uk") {
    return UA
  }
  if (lng === "ar") {
    return AR
  }
}


export const movieTitleHandler = (lng: string, item: any) => {
  if (lng === "en") {
    return item.title
  }
  if (lng === "uk") {
    return item?.title_ua
  }
  if (lng === "ar") {
    return item?.title_ar
  }
  if (lng === "ru") {
    return item?.title_ru
  }

  return item?.title
}

export const posterPathHandler = (lng: string, item: any) => {
  if (lng === "en") {
    return item.poster_path
  }
  if (lng === "uk") {
    return item?.poster_path_ua
  }
  if (lng === "ar") {
    return item?.poster_path_ar
  }
  if (lng === "ru") {
    return item?.poster_path_ru
  }

  return item?.poster_path
}

export function formatDateToDMY(isoDateStr: string) {
  const date = new Date(isoDateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы от 0 до 11
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}
