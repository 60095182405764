import styles from "./Favourites.module.scss";
import SideBar from "../../components/sideBar/SideBar";
import React, {useCallback, useEffect, useState} from "react";
import PeriodModule from "../filters/components/PeriodModule";
import useInterfaceContext from "../../components/providers/interface";
import {useGetFavouritesFilmsListQuery} from "../../store/api/filmAPI";
import GenresModule from "../filters/components/GenresModule";
import Footer from "../../components/footer/Footer";
import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../../components/loader/Loader";
import Header from "../../components/header/Header";
import {useTranslation} from "react-i18next";
import {debounce, languageHandler} from "../../utils/helpers";
import {useDispatch} from "react-redux";
import {setSearch} from "../../store/slices/filters.slice";
import {useAppSelector} from "../../store/store";
import MoviesList from "../search/MoviesList/MoviesList";
import SearchFieldIcon from "../../components/icons/searchIcons/searchField";
import Input from "../../components/shared/Input";
import useLikeClick from "../../hooks/useLikeClick";
import useFavouriteClick from "../../hooks/useFavouriteClick";
import SavedSmall from "../../components/icons/services/SavedSmall";
import {Link} from "react-router-dom";
import {ROUTES} from "../../constants/routes";
import {
  cleanUpFavouritesPage, setDataToFavouritesList, setFavouritesPage, setGenresFavouritesValue,
  setPeriodFavouritesValue, setPeriodFavouritesValueUI, setPrevFavouritesPage
} from "../../store/slices/favouritesList.slice";

interface IQuery {
  language: string;
  sort_by: string;
  include_video: boolean;
  "release_date_gte"?: string;
  "release_date_lte"?: string;
  search?: string;
  with_genres?: string;
}

const Favourites = () => {
  const accessToken = localStorage.getItem("accessToken");
  const search = useAppSelector((state: any) => state.filters.search)
  const userData = useAppSelector((state: any) => state.auth.userData);
  const filmList = useAppSelector((state: any) => state.favouritesList.commonList)
  const page = useAppSelector((state: any) => state.favouritesList.page)
  const periodValue = useAppSelector((state: any) => state.favouritesList.periodValue)
  const periodValueUI = useAppSelector((state: any) => state.favouritesList.periodValueUI)
  const genresValue = useAppSelector((state: any) => state.favouritesList.genresValue)
  const prevPage = useAppSelector((state: any) => state.favouritesList.prevPage)

  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState(search || '');
  const [isFocused, setIsFocused] = useState(false);

  const dispatch = useDispatch();
  const { isMobile } = useInterfaceContext();
  const { windowSize } = useWindowSize();
  const { t, i18n } = useTranslation();
  const { likesList, likeHandler } = useLikeClick();
  const { favoritesList, favouritesHandler } = useFavouriteClick();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const clearGenresHandler = () => {
    dispatch(cleanUpFavouritesPage());
    dispatch(setGenresFavouritesValue([]));
  }

  const clearPeriodHandler = () => {
    dispatch(cleanUpFavouritesPage());
    dispatch(setPeriodFavouritesValue([1980, 2024]));
    dispatch(setPeriodFavouritesValueUI([1980, 2024]));
  }

  const setGenresValueHandler = (itemId: any) => {
    dispatch(cleanUpFavouritesPage());
    const result = genresValue.includes(itemId)
      ? genresValue.filter((id: any) => id !== itemId) : [...genresValue, itemId];
    dispatch(setGenresFavouritesValue(result));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setPeriodForRequest = useCallback(
    debounce((value: number[]) => {
      dispatch(cleanUpFavouritesPage());
      dispatch(setPeriodFavouritesValue(value));
    }, 400),
    []
  );

  const setPeriodHandler = (periodValue: number[]) => {
    dispatch(setPeriodFavouritesValueUI(periodValue));
    setPeriodForRequest(periodValue);
  };

  const queryHandler = () => {
    const query: IQuery = {
      language: languageHandler(i18n.language) as string,
      sort_by: "popularity.desc",
      include_video: true,
    };

    if (periodValue[0] !== 1980 || periodValue[1] !== 2024) {
      query["release_date_gte"] = `${periodValue[0]}-01-01`;
      query["release_date_lte"] = `${periodValue[1]}-12-31`;
    }

    if (searchValue) {
      query.search = searchValue;
    }

    if (genresValue.length) {
      query.with_genres = genresValue.join('|');
    }

    return query;
  };

  const {data, isLoading, isFetching, error, refetch} = useGetFavouritesFilmsListQuery({
    mainFilter: '/movies/favorites',
    queries: {
      ...queryHandler(),
      page: page
    },
  });

  useEffect(() => {
    let timeout: any;
    if (!userData?.email) {
      timeout = setTimeout(() => {
        refetch();
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [userData, refetch]);

  useEffect(() => {
    if ((!isFetching && data && page > prevPage) || !filmList?.length) {
      dispatch(setDataToFavouritesList(data?.results));
      dispatch(setPrevFavouritesPage(page));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isFetching]);


  const setPageHandler = () => {
    dispatch(setFavouritesPage(1));
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(cleanUpFavouritesPage());

    setInputValue(event.target.value);
    dispatch(setSearch(event.target.value));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    return () => {
      dispatch(cleanUpFavouritesPage())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section>
        <Header className={styles.headerWrapper} />
        {windowSize !== "mobile" ? <SideBar /> : null}
        {accessToken ? (
          <div className={styles.container}>
            <div className={styles.leftColumnWrapper}>
              <div className={styles.leftColumnTopContent}>
                <MoviesList
                  data={filmList}
                  error={error}
                  setPageHandler={setPageHandler}
                  isFetching={isFetching}
                  isLastData={data.next === null}
                  likeHandler={likeHandler}
                  likesList={likesList?.data?.results}
                  favoritesList={favoritesList?.data?.results}
                  favouritesHandler={favouritesHandler}
                  title={t('selected')}
                  isFavoritePage={true}
                />
              </div>
            </div>
            {windowSize !== "mobile" && (
              <div className={styles.contentWrapper}>
                <div className={`${styles.searchFieldWrapper} ${isFocused ? styles.focused : ''}`}>
                  <SearchFieldIcon />
                  <Input
                    placeHolder={t('search')}
                    className={styles.input}
                    labelClassName={styles.label}
                    value={inputValue}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleInputChange}
                  />
                </div>
                <PeriodModule
                  fromPageFlag={true}
                  setPeriodValue={setPeriodHandler}
                  isMobile={!isMobile}
                  className={styles.periodModule}
                  period={periodValueUI}
                  clearFiltersHandler={clearPeriodHandler}
                />
                <GenresModule
                  fromPageFlag={true}
                  setGenresValue={setGenresValueHandler}
                  isMobile={!isMobile}
                  className={styles.genresModule}
                  genres={genresValue}
                  clearFiltersHandler={clearGenresHandler}
                />
              </div>
            )}
          </div>
        ) : (
          <div className={styles.unautorisedWrapper}>
            <SavedSmall />
            <div className={styles.unautorisedTitle}>{t('loginOrReg')}</div>
            <div className={styles.unautorisedText}>{t('loginOrRegText')}</div>
            <Link to={ROUTES.LOGIN}>
              <div className={styles.unautorisedBtn}>{t('auth')}</div>
            </Link>
          </div>
        )}
        <Footer source={''}/>
      </section>
    </>
  );
};

export default Favourites;
