import axiosInstance from "../api/axios";
import {useAppDispatch} from "../store/store";
import {setSubscriptionsList} from "../store/slices/subscriptions.slice";
import useAccount from "./useAccount";

const useSubscriptions = () => {
  const dispatch = useAppDispatch();
  const { getAccountDetails } = useAccount();

  const getSubscriptions = async () => {
    const response = await axiosInstance.get('/commercials/products/');
    dispatch(setSubscriptionsList(response.data?.results));
  }

  const subscribe = async (id: number) => {
    const response = await axiosInstance.post('/commercials/subscription/', {
      product_id: id
    });
    const formHtml = response.data;

    if (formHtml) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = formHtml;

      const form = tempDiv.querySelector('form');
      if (form) {
        document.body.appendChild(form);

        form.submit();
      } else {
        console.error('Форма не найдена в ответе.');
      }
    }
    console.log(response, 'response')
  }

  const cancelSubscribe = async () => {
    const response = await axiosInstance.post('/commercials/subscription/cancel/');
    console.log(response, 'response')
    await getAccountDetails();
  }
  return {
    getSubscriptions,
    cancelSubscribe,
    subscribe
  }
}


export default useSubscriptions;
