import {useTranslation} from "react-i18next";
import styles from "../styles/profile.module.scss";
import Button from "../../../components/shared/Button/Button";
import {formatDateToDMY} from "../../../utils/helpers";

const discount = '';

interface IProps {
  currentId: number;
  nextPay: string;
  currentSubItem: any
}
const MySubscriptionsCard = ({
   currentId,
   nextPay,
   currentSubItem
}: IProps) => {
  const {t, i18n} = useTranslation();
  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'
  const currentCost = i18n.language === 'uk' ? currentSubItem.price_ua : currentSubItem.price;
  return (
    <div className={styles.mySubCardWrapper}>
      <div className={styles.mySubCardWrapperContent}>
        <div className={styles.mySubCardAmount}>
          <div dir={dirData} className={styles.mySubCardAmountTitle}>
            {currentId === 1 ? t('oneMonth') : t('oneYear')}
          </div>
          {discount && <div className={styles.mySubDiscount}>{t('shortDiscount')} {discount}</div>}
        </div>
        <div className={styles.mySubCardExpiration}>
          <p>{t('nextPayment')}</p>
          <p>{formatDateToDMY(nextPay)}</p>
        </div>
      </div>
      <div className={styles.mySubCardWrapperContent}>
        <Button
          title={t('continueWithDiscount')}
          submit={() => {}}
          className={styles.mySubCardButton}
        />
        <div className={styles.mySubCardInfo}>
          <p>{currentCost} {i18n.language === 'uk' ? t('uah') : t('usd')}</p>
          {/*<p>97 {t('perMth')}</p>*/}
        </div>
      </div>
    </div>
  )
};

export default MySubscriptionsCard;
