import styles from "../styles/profile.module.scss";
import SubscriptionCard from "./SubscriptionCard";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../store/store";
import {Subscription} from "../../../types";
import useSubscriptions from "../../../hooks/useSubscriptions";

const SubscriptionsData = [
  {
    id: 1,
    amount: 'oneMonth',
    total: 97,
    price: 97,
    total_ua: 97,
    price_ua: 97,
    discount: ''
  },
  {
    id: 2,
    amount: "oneYear",
    total: 864,
    price: 72,
    total_ua: 864,
    price_ua: 72,
    discount: '25%'
  },
]
const Subscriptions = () => {
  const [subsData, setSubsData] = useState(SubscriptionsData);
  const [priceUpToDate, setPriceUpToDate] = useState<boolean>(false);

  const {subscribe} = useSubscriptions();
  const {t, i18n} = useTranslation();


  const subscriptionsList = useAppSelector((state: any) => state.subscriptions.subscriptionsList)

  useEffect(() => {
    if (subscriptionsList.length) {
      setSubsData((prevState) => {
        const data = prevState;

        subscriptionsList.forEach((item: Subscription) => {
          if (item?.billing_period === 'month') {
            data[0].total = +item?.price;
            data[0].price =  +item?.price;
            data[0].total_ua = +item?.price_ua;
            data[0].price_ua =  +item?.price_ua;
          }

          if (item.billing_period === 'year') {
            data[1].total = +(+item?.price / 12).toFixed(2);
            data[1].price =  +item?.price;
            data[1].total_ua = +(+item?.price_ua / 12).toFixed(2);
            data[1].price_ua =  +item?.price_ua;
          }
        })

        setPriceUpToDate(true);
        return data;
      })
    }
  }, [subscriptionsList])



  return (
    <div>
      <div className={styles.subscriptionSubTitle}>
        {t('subBlockTitle')}
      </div>
      <div className={styles.subscriptionDescr}>{t('choosePlan')}</div>
      {subsData?.map((item) => (
        <SubscriptionCard
          subscribe={subscribe}
          priceUpToDate={priceUpToDate}
          key={item.id}
          amount={t(item.amount)}
          total={i18n.language === 'uk' ? item.total_ua : item.total}
          price={i18n.language === 'uk' ? item.price_ua : item.price}
          discount={item.discount}
          id={item.id}
        />
      ))}
    </div>
  )
}

export default Subscriptions;
