import React, { useEffect, useState } from "react"
import styles from "../../_styles/Choose.module.scss"
import {ISectionItemProps} from "../../_types/interfaces";
import useInViewport from "../../../../hooks/useInViewPort";
import ReactPlayer from "react-player";
import MobileCover from "../MobileComponents/MobileCover";
import MobileBottomInfoBlock from "../MobileComponents/MobileBottomInfoBlock";
import MobileProgressController from "../MobileComponents/MobileProgressController";
import useInterfaceContext from "../../../../components/providers/interface";
import {useDispatch} from "react-redux";
import {setMutedState} from "../../../../store/slices/filters.slice";
import {setCurrentItem, setCurrentVideoId, setLastVisitedId} from "../../../../store/slices/currentItem.slice";
import {ROUTES} from "../../../../constants/routes";
import {useNavigate} from "react-router-dom";
import FiltersHeader from "../FiltersHeader/FiltersHeader";
import {useGetCurrentVideoQuery} from "../../../../store/api/filmAPI";
import {useTranslation} from "react-i18next";
import {languageHandler} from "../../../../utils/helpers";
import {AdSkip} from "../../../../components/shared/AdsSkip/AdsSkip";

const errors = [150];

const MobilePlayer = ({
  item,
  horizontal,
  currentId,
  filterErrorItem,
  skipAdsHandler
}: ISectionItemProps) => {
  const [url, setUrl] = useState("")
  const [player, setPlayer] = useState(null)
  const [time, setTime] = useState<number | number[]>(0)
  const [maxValue, setMaxValue] = useState<number | number[]>(0)
  const [ready, setReady] = useState(false)
  const [muted, setMuted] = useState(true);
  const [forcePlay, setForcePlaying] = useState(true)
  const [paused, setPaused] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const {isMobile} = useInterfaceContext();
  const { inViewport, targetRef } = useInViewport()

  const { data } = useGetCurrentVideoQuery({
      queries: { language: languageHandler(i18n.language) },
      id: item.id
    },
    {
      skip: item.commercial_url ? true : !inViewport
    }
  );

  const forcePlayHAndler = () => {
    if (paused) {
      setMuted(true)

      setForcePlaying(false)
      setTimeout(() => {
        setForcePlaying(true);
      }, 100)

      setPaused(false)
    }
  }

  const mutedIconHandler = () => {
    dispatch(setMutedState(!muted))
  }

  const clickHandler = () => {
    if (ready) {
      navigate(`${ROUTES.DETAILS}/${item.id}`);
      dispatch(setLastVisitedId(item.id as number));
    }
  }

  useEffect(() => {
    if (!item.commercial_url) {
      const trailer = data?.results.find((item: any) => item.type === 'Trailer') || data?.results[0]
      setUrl(trailer ? trailer?.key : '')
    } else {
      setUrl(item.commercial_url)
    }
  }, [data, item])

  useEffect(() => {
    if (inViewport) {
      dispatch(setCurrentVideoId(item.id))
      dispatch(setCurrentItem(item))
    }

    if (!inViewport) {
      setMuted(true)
    }
  }, [inViewport, item, dispatch, setMuted])

  return (
    <>
      <div
        ref={targetRef}
        className={styles.itemWrapper}>
        {horizontal && <FiltersHeader/>}
        {inViewport && (
          <>
            <div className={`${styles.player} ${!horizontal && styles.playerHorizontal}`}>
              <MobileCover
                mutedIconHandler={mutedIconHandler}
                muted={muted}
                setMuted={setMuted}
                ready={ready}
                forcePlayHAndler={forcePlayHAndler}
              />
              {item?.commercial_url && inViewport && (<AdSkip onSkip={() => skipAdsHandler("down", item.id)} />)}
              {forcePlay ? (
                <ReactPlayer
                  controls={false}
                  width={"100%"}
                  height={horizontal ? "100%" : "130%"}
                  playsinline={true}
                  progressInterval={time as number}
                  onProgress={(value: any) => setTime(Math.round(value.playedSeconds))}
                  playing={true}
                  loop={true}
                  style={
                   horizontal ? {background: '#000000'} : {position: 'relative', top: '-30px'}
                  }
                  url={`https://www.youtube.com/watch?v=${url}`}
                  muted={muted}
                  onPause={() => setPaused(true)}
                  onError={(e: any) => {
                    if (errors.includes(e)) {
                      console.log(e, 'error')
                      filterErrorItem(item.id)
                    }
                  }}
                  onReady={(e: any) => {
                    setPlayer(e["player"])
                    const duration = e["player"].getDuration()
                    setMaxValue(duration)
                    setTimeout(() => {
                      setReady(true)
                    }, 200)
                  }}
                />
              ) : (
                <div style={{height: "350px", width: "100%", background: '#000000'}}/>
              )}
            </div>
            <div className={styles.bottomControlWrapper}>
              {horizontal && (
                <MobileBottomInfoBlock
                  item={item}
                  currentItemId={item.id}
                  voteCount={item.vote_count}
                  isMobile={isMobile}
                  clickHandler={clickHandler}
                />
              )}
              <div className={styles.mobileProgressController}>
                <MobileProgressController
                  maxValue={maxValue as number}
                  time={time as number}
                  player={player as any}
                  setTime={setTime}
                  isMobile={isMobile}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default MobilePlayer
