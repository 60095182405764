import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {Subscription} from "../../types";

type DefaultState = {
  subscriptionsList: Subscription[]
}

const initialState: DefaultState = {
  subscriptionsList: [],
}

export const SubscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: initialState,
  reducers: {
    setSubscriptionsList: (state, action: PayloadAction<Subscription[]>) => {
      state.subscriptionsList = action.payload
    },
  },
})

export default SubscriptionsSlice.reducer
export const { setSubscriptionsList } = SubscriptionsSlice.actions
