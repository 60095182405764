import React, { useState, useEffect } from 'react';
import styles from './AdsSkip.module.scss';
import {useTranslation} from "react-i18next";

interface AdSkipProps {
  onSkip: () => void;
  initialSeconds?: number;
}

export function AdSkip({ onSkip, initialSeconds = 15 }: AdSkipProps) {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [canSkip, setCanSkip] = useState(false);

  const {t} = useTranslation();

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setCanSkip(true);
    }
  }, [seconds]);

  return (
    <button
      onClick={canSkip ? onSkip : undefined}
      className={`${styles.adSkip} ${canSkip ? styles.enabled : styles.disabled}`}
    >
      {canSkip
        ? t('scBtn1')
        : `${t('scBtn2')} ${seconds} ${t('scBtn3')}`
      }
    </button>
  );
}
