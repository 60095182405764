import React from "react"

import ActorCard from "./ActorCard"
import styles from "./MainActorsList.module.scss"
import Heading from "../../../../components/shared/Heading";
import {useGetCastsListQuery} from "../../../../store/api/filmAPI";
import DotsLoader from "../../../../components/icons/DotsLoader";
import {useParams} from "react-router-dom";
import {EN} from "../../../../constants/local";

type Props = {
  sectionTitle: string
}

type Person = {
  adult: boolean;
  gender: number;
  id: number;
  known_for_department: string;
  name: string;
  original_name: string;
  popularity: number;
  profile_path: string;
  cast_id: number;
  character: string;
  credit_id: string;
  order: number;
};

const MainActorsList = ({ sectionTitle }: Props) => {
  const { id } = useParams();

  const { data, isLoading } = useGetCastsListQuery({
    queries: { language: EN },
    id: id,
  })

  if (isLoading) {
    return <DotsLoader />
  }

  return (
    <div className={styles.wrapper}>
      <Heading title={sectionTitle} />
      <div id={'cardWrapper'} className={styles.cardWrapper}>
        {data.cast.map((item: Person, index: number) => item.profile_path ? (
          <ActorCard
            key={index}
            fullName={item.original_name}
            description={item.character}
            image={`${process.env.REACT_APP_IMG_BASE_URL}${item.profile_path}`}
          />
        ) : (null))}
      </div>
    </div>
  )
}

export default MainActorsList
