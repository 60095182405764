import React, {Suspense, useEffect, useState} from "react"

import styles from "../../_styles/Choose.module.scss"
import {ISectionItemProps} from "../../_types/interfaces";
import useInViewport from "../../../../hooks/useInViewPort";
import ReactPlayer from "react-player";
import PlayerControllerWrapper from "../playerController/PlayerControllerWrapper";
import ChooseFooter from "../DesctopComponents/ChooseFooter";
import MobileCover from "../MobileComponents/MobileCover";
import {useDispatch} from "react-redux";
import {setCurrentItem, setCurrentVideoId, setLastVisitedId} from "../../../../store/slices/currentItem.slice";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../constants/routes";
import FiltersHeader from "../FiltersHeader/FiltersHeader";
import {useGetCurrentVideoQuery} from "../../../../store/api/filmAPI";
import {useTranslation} from "react-i18next";
import {languageHandler} from "../../../../utils/helpers";
import {AdSkip} from "../../../../components/shared/AdsSkip/AdsSkip";
const errors = [150];

const DesctopPlayer = ({
  item,
  muted,
  setMuted,
  filterErrorItem,
  skipAdsHandler
}: ISectionItemProps) => {
  const [url, setUrl] = useState("")
  const [player, setPlayer] = useState(null)
  const [time, setTime] = useState<number | number[]>(0)
  const [maxValue, setMaxValue] = useState<number | number[]>(0)
  const [displayVolume, setDisplayVolume] = useState<boolean>(false)
  const [ready, setReady] = useState(false)
  const { i18n } = useTranslation();
  const { inViewport, targetRef } = useInViewport(url)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const { data } = useGetCurrentVideoQuery({
      queries: { language: languageHandler(i18n.language) },
      id: item.id
    },
    {
      skip: item.commercial_url ? true : !inViewport
    }
  );

  useEffect(() => {
    if (!item.commercial_url) {
      const trailer = data?.results.find((item: any) => item.type === 'Trailer') || data?.results[0]
      setUrl(trailer ? trailer?.key : '')
    } else {
      setUrl(item.commercial_url)
    }
  }, [data, item])

  useEffect(() => {
    if (inViewport) {
      dispatch(setCurrentVideoId(item.id))
      dispatch(setCurrentItem(item))
    }

    if (!inViewport) {
      setReady(false)
    }
  }, [inViewport, item, dispatch])

  const clickHandler = () => {
    if (ready) {
      navigate(`${ROUTES.DETAILS}/${item.id}`);
      dispatch(setLastVisitedId(item.id as number));
    }
  }

  const mutedIconHandler = () => {
    if (setMuted) {
      setMuted(!muted)
    }
  }

  useEffect(() => {
    const element = document.getElementById('mobileCover');
    if (!element) return;

    let timerId: number | any = null;

    const handleMouseMove = (e: any) => {
      if (!displayVolume) setDisplayVolume(true);

      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setDisplayVolume(false);
      }, 3000);
    };

    const handleMouseLeave = (event: any) => {
      if (element.contains(event.relatedTarget)) {
        return;
      }
      setDisplayVolume(false);
    };

    element.addEventListener('mousemove', handleMouseMove);
    element.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      element.removeEventListener('mousemove', handleMouseMove);
      element.removeEventListener('mouseleave', handleMouseLeave);
      clearTimeout(timerId);
    };
  }, [displayVolume, inViewport]);

  return (
    <>
      <div ref={targetRef} className={styles.itemWrapperDesctop}>
        <div className={styles.desctopPlayer}>
          <Suspense fallback={<div></div>}>
            <FiltersHeader />
          </Suspense>
          {inViewport && !item?.commercial_url && (
            <div id={'mobileCover'}>
              <MobileCover
                mutedIconHandler={mutedIconHandler}
                muted={muted}
                setMuted={setMuted}
                ready={ready}
              />
              <PlayerControllerWrapper
                time={time}
                maxValue={maxValue}
                player={player}
                setTime={setTime}
                displayVolume={displayVolume}
              />
            </div>
          )}
          {item?.commercial_url && inViewport && (<AdSkip onSkip={() => skipAdsHandler("down", item.id)} />)}
          {inViewport ? (
            <ReactPlayer
              controls={false}
              width={"100%"}
              height={"100%"}
              playsinline={true}
              progressInterval={time as number}
              volume={100}
              onProgress={(value: any) => setTime(Math.round(value.playedSeconds))}
              playing={true}
              loop={true}
              muted={muted}
              url={`https://www.youtube.com/watch?v=${url}`}
              onError={(e: any) => {
                if (errors.includes(e)) {
                  console.log(e, 'error')
                  filterErrorItem(item.id)
                }
              }}
              onReady={(e: any) => {
                setPlayer(e["player"])
                const duration = e["player"].getDuration()
                setMaxValue(duration)

                setTimeout(() => {
                  setReady(true)
                }, 200)
              }}
            />
          ) : (
            <div style={{ height: "350px", width: "100%" }} />
          )}
          <div className={styles.bottomMenu}>
            {!item?.commercial_url ? (
              <ChooseFooter
                item={item}
                inViewport={inViewport}
                currentItemId={item?.id}
                voteCount={item.vote_count}
                clickHandler={clickHandler}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default DesctopPlayer
