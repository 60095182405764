import styles from "../styles/profile.module.scss";
import Button from "../../../components/shared/Button/Button";
import {useTranslation} from "react-i18next";

interface IProps {
  id: number,
  amount: string,
  total: number,
  price: number,
  discount: string,
  priceUpToDate: boolean,
  subscribe: (id: number) => void,
}
const SubscriptionCard = ({
  id,
  amount,
  total,
  price,
  discount,
  priceUpToDate,
  subscribe
}: IProps) => {
  const {t, i18n} = useTranslation();
  const dirData = i18n.language === 'ar' ? 'rtl' : 'ltr'

  return (
    <div className={styles.subscriptionCardWrapper}>
      <div>
        <div className={styles.cartAmountWrapper}>
          <div className={styles.amount}>{amount}</div>
          {discount && <div dir={dirData} className={styles.discount}>{t('shortDiscount')} {discount}</div>}
        </div>
        <div className={styles.total}>
          {priceUpToDate ? (total) : "..."}
          {t('perMth')}
        </div>
      </div>
      <div className={styles.cardRightContent}>
        <div className={styles.priceWrapper}>
          <div className={styles.price}>
            {priceUpToDate ? (price) : '...'}
            <span>
              {i18n.language === 'uk' ? t('uah') : t('usd')}
            </span>
          </div>
          {discount && <div className={styles.regularPrice}>999 {i18n.language === 'uk' ? t('uah') : t('usd')}</div>}
        </div>
        <div>
          <Button
            className={styles.connectButton}
            title={t('subscribe')}
            submit={() => subscribe(id)}
          />
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCard;
