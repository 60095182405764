import {useTranslation} from "react-i18next";
import Button from "../../../components/shared/Button/Button";
import styles from "../styles/profile.module.scss";
import MySubscriptionsCard from "./MySubscriptionsCard";
import {useDispatch} from "react-redux";
import {setOpenModal} from "../../../store/slices/modals.slice";
import {useAppSelector} from "../../../store/store";

interface IProps {
  planId: number;
  nextPay: string;
  currentOrder: any;
  isSubscribed: boolean;
  chooseSubscription: (tab: number) => void;
}
const MySubscriptions = ({
  planId,
  nextPay,
  currentOrder,
  isSubscribed,
  chooseSubscription
}: IProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const subscriptionsList = useAppSelector((state: any) => state.subscriptions.subscriptionsList)

  const removeSubscriptionHandler = () => {
    dispatch(setOpenModal('removeSubscription'))
  }

  return !isSubscribed ? (
    <div>
      {currentOrder.status === 'pending' && (
        <div className={styles.mySubsTitle}>{t('subRev')}</div>
      )}
      <p className={styles.mySubsTitle}>{t('emptySubs')}</p>
      <Button
        title={t('chooseSub')}
        submit={() => chooseSubscription(0)}
        className={styles.newSubButton}
      />
    </div>
  ) : (
    <div>
      {currentOrder.status === 'pending' && (
        <div className={styles.mySubsTitle}>{t('subRev')}</div>
      )}
      <p className={styles.mySubsTitle}>{t('subListTitle')}</p>
      <p className={styles.mySubsListTitle}>{t('mySubs')}</p>
      <div className={styles.subsListWrapper}>
        <MySubscriptionsCard
          currentId={planId}
          nextPay={nextPay}
          currentSubItem={subscriptionsList.find((item: any) => item.id === planId)}
        />
      </div>
      <div className={styles.mySubsBottomMenu}>
        <p>{t('payHistory')}</p>
        <p onClick={removeSubscriptionHandler}>{t('removePayment')}</p>
      </div>
    </div>
  )
}

export default MySubscriptions
